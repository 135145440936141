import Navbar from "../../Components/Navbar";

const Inbox = () => {
  return (
    <div className="container-fluid">
      <Navbar />
      {/* Other content for the Inbox page can go here */}
      <div className="row">
        <div className="col">
          <h1>Inbox Page</h1>
          {/* Add your inbox content here */}
        </div>
      </div>
    </div>
  );
};

export default Inbox;